const header = document.querySelector('.js-header');
const headerLogo = document.querySelector('.js-header-logo');
const mobileMenu = document.querySelector('.js-mobile-menu');
const headerMenuLinks = document.querySelectorAll('.js-header-menu a');
const headerMenuLinksArrows = document.querySelectorAll('.js-header-menu-arrow');

let scrollPos = 0;
window.addEventListener('scroll', fixedHeader);

function fixedHeader() {
    if ((document.body.getBoundingClientRect()).top > scrollPos) {
        header.classList.remove('-top-28');
    } else {
        header.classList.add('-top-28');
    }
    scrollPos = (document.body.getBoundingClientRect()).top;
}
