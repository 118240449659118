const resizingSelect = document.querySelector(".js-select--as-link");
const helperElement = document.querySelector(".js-select-helper");

if(resizingSelect){
    resizingSelect.addEventListener("change", initResize);
}

function initResize(event) {
    const el = this;
    helperElement.innerHTML = event.target.querySelector(
        "option:checked"
    ).innerText;
    resize(helperElement.offsetWidth, el);
}

function resize(width, el) {
    el.querySelector('select').style.width = width+10+'px';
}
