window.onFavorite = function(el, id, from_page) {
    const $el = $(el);

    $.request('onFavorite', {
        data: {
            fav: id,
            from_page: from_page,
        },
        flash: 1,
        success: function (data) {
            this.success(data).done(function () {
                if (data.status) {
                    // $el.toggleClass('hidden');
                    $el.find('.js-card-property-favorite-icon').toggleClass('hidden');
                } else {
                    Fancybox.show([{src: "#login-popup", type: "inline"}]);
                }
            });
        },
    })
}
