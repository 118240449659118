window.addEventListener('scroll', showToTopButton);

function showToTopButton() {
    const toTopButton = document.getElementById("to-top-button");
    if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
        toTopButton.style.display = "block";
    } else {
        toTopButton.style.display = "none";
    }
}

window.scrollToTop = function () {
    window.scrollTo({top: 0, behavior: 'smooth'});
}
